/* global config, Promise */

'use strict';

angular.module('managerApp').controller('TabletsController', ($scope, $rootScope,
        $location, $cookies, $routeParams, $window, $mdDialog, toast, $_tablets, procedureTools) => {

//------------------------------------------------------------------------------

    const currentDir = 'app/tablets/';
    const path = $location.path();

    $scope.config = config;

    $scope.firstRun = true;
    $scope.count = 0;
    $scope.items = [];
    $scope.newItem = {};

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: $cookies.get('pagination.tablets.itemsPerPage') || 10,
        totalItems: 0,
        pageChanged: () => {
            $cookies.put('pagination.tablets.itemsPerPage', $scope.pagination.itemsPerPage);
            $scope.getTablets();
        }
    };

    $scope.error = null;
    $scope.offlineTabletsNb = 0;
    $scope.item_id = null;
    $scope.partnerId = $routeParams.id;
    $scope.tabletId = $routeParams.id;
    $scope.isEdit = typeof $scope.tabletId !== 'undefined';
    $scope.tablet = null;

    $scope.tabletPartners = [];

//------------------------------------------------------------------------------

    $scope.openMenu = function ($mdMenu, e) {
        $mdMenu.open(e);
    };

    $scope.showSearchPanel = () => {
        $rootScope.$broadcast('show-search-panel');
    };

    $rootScope.$on('get-tablets', (e) => {
        $scope.pagination.filters = e.targetScope.search.filters;
        $scope.getTablets();
    });

//------------------------------------------------------------------------------    
    const getPartnersTablets = () => {
        let params = {
            partners: 1
        };
        const path_parts = $scope.currentPath.split("/")
            $scope.pagination.filters= path_parts.length>3 ? {PartnerId:path_parts[4]} :  {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        $_tablets.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };
    function getTablets() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                let value = $scope.pagination.filters[i];
                if (value !== '') {
                    if (i === 'tablet') {
                        if (value === null) {
                            delete params.ClientId;
                        } else {
                            params.ClientId = value._id;
                        }
                    } else {
                        params[i] = $scope.pagination.filters[i];
                    }
                }
            }
        }
        $_tablets.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.count = data.count;
            $scope.items = data.items || [];
            $scope.pagination.totalItems = data.count;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.TabletPartner.Client.personType = 'personne-physique';
                item.tabletName = procedureTools.getPersonName(item.TabletPartner.Client);
                if (item.enabled && !item.online) {
                    $scope.offlineTabletsNb++;
                }
            }
            $rootScope.offlineTabletsNb = $scope.offlineTabletsNb;
            $scope.$apply();
        }).catch((err) => {
            console.log(err);
            toast.show(err, 'error');
        });
    }

    $scope.ping = (item, index) => {
        $_tablets.get({
            id: item._id,
            ping: true
        }).then((data) => {
            if (data) {
                item.online = data.online;
                item.lastPing = data.lastPing;
            }
            $scope.$apply();
        }).catch((err) => {
            toast.show(err, 'error');
        });
    };

    $scope.toggleActive = (item) => {
        if (confirm('Voulez-vous vraiment ' + (item.enabled ? 'dés' : '') + 'activer cette tablette ?')) {
            item.enabled = !item.enabled;
            $_tablets.update(item, item._id).then(() => {
                toast.show('La tablette "' + item.name + '" a bien été mise à jour.', 'success');
            }).catch(() => {
                toast.show('La tablette "' + item.name + '" n\'a pas pu être mise à jour.', 'error');
                item.enabled = !item.enabled;
            });
        }
    };

    $scope.showDeleteConfirm = ($event, item) => {
        $mdDialog.show({
            templateUrl: 'app/tablets/delete.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, $_tablets, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        $_tablets.delete(itemId).then(() => {
                            toast.show('La tablette a bien été supprimée.', 'success');
                            $scope.getTablets();
                        }).catch((err) => {
                            toast.show(err);
                        });
                    }//
                };
            }
        });
    };

    $scope.updateActivationCode = (item) => {
        $_tablets.updateActivationCode(item._id).then((tablet) => {
            item.activationCode = tablet.activationCode;
            toast.show('Le code d\'activation de la tablette a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show('Le code d\'activation de la tablette n\'a pas pu être mis à jour.', 'error');
        });
    };

    $scope.startRemoteSession = (item) => {
        if (!item.remoteId) {
            return;
        }
        $window.open('anydesk://' + item.remoteId, '_blank');
    };

    // by default, instant processing is enabled
    $scope.disableInstantProcessing = (item) => {
        setInstantProcessing(item === 'all' ? item : item._id, false);
    };

    $scope.enableInstantProcessing = (item) => {
        setInstantProcessing(item === 'all' ? item : item._id, true);
    };

    function setInstantProcessing(target, enable) {
        let v = (enable ? '' : 'dé') + 'activer',
                t = target === 'all' ? 'toutes les tablettes' : 'cette tablette',
                data = {instantProcessing: enable};
        if (confirm('Voulez-vous vraiment ' + v + ' le traitement instantané pour ' + t + ' ?')) {
            $_tablets.update(data, target).then(() => {
                showUpdateToast(target === 'all', true);
                updateTarget(target, 'instantProcessing', enable);
            }).catch(() => {
                showUpdateToast(target === 'all', false);
            });
        }
    }

    // by default, standby mode is disabled
    $scope.enableStandby = (item) => {
        setStandby(item === 'all' ? item : item._id, true);
    };

    $scope.disableStandby = (item) => {
        setStandby(item === 'all' ? item : item._id, false);
    };

    function setStandby(target, enable) {
        let v = (enable ? '' : 'dé') + 'activer',
                t = target === 'all' ? 'toutes les tablettes' : 'cette tablette',
                data = {standby: enable};
        if (confirm('Voulez-vous vraiment ' + v + ' le mode "veille" pour ' + t + ' ?')) {
            $_tablets.update(data, target).then(() => {
                showUpdateToast(target === 'all', true);
                updateTarget(target, 'standby', enable);
            }).catch(() => {
                showUpdateToast(target === 'all', false);
            });
        }
    }

    function updateTarget(target, attr, value) {
        for (let i = 0; i < $scope.items.length; i++) {
            let item = $scope.items[i];
            if (target === 'all') {
                item[attr] = value;
            } else {
                if (item._id === target) {
                    item[attr] = value;
                    break;
                }
            }
        }
    }
    function showUpdateToast(allItems, success) {
        if (success) {
            toast.show(allItems
                    ? 'Les tablettes ont bien été mises à jour'
                    : 'La tablette a bien été mise à jour', 'success');
        } else {
            toast.show(allItems
                    ? 'Les tablettes n\'ont pas pu être mises à jour'
                    : 'La tablette n\'a pas pu être mise à jour', 'error');
        }
    }

    function getTablet(id) {
        if (!id)
            return;
        $_tablets.get({id: id}).then((tablet) => {
            $scope.tablet = tablet;
            getProcedures($scope.tabletId);

        }).catch((err) => {
            toast.show('Tablette introuvable', 'error').then(() => {
                $location.path('/tablets');
            });
        });
    }

    function getProcedures(id) {
        let params = {
            id: $scope.tabletId
        };
        $_tablets.getproced(params, $scope.paginationproced.currentPage,
                $scope.paginationproced.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;
            }

            $scope.paginationproced.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.submitTabletForm = (form) => {
        let tablet = $scope.tablet;
        if (form.$valid) {
            let promise = $scope.isEdit ? $_tablets.update(tablet, $scope.tabletId) : $_tablets.add(tablet);
            promise.then(() => {
                toast.show('La tablette a bien été ' + ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true, 2500).then(() => {
                    if (!$scope.isEdit) {
                        $scope.firstRun = true;
                        $location.path('/tablets');
                    }
                });
            }).catch((err) => {
                toast.show(err, 'error');
            });
        } else {
            toast.show('Veuillez remplir tous les champs obligatoires.', 'error', true);
        }
    };

    $scope.getPartners = (name = null, fb = null, ignoreCache = false) => {
        let cacheName = 'tabletPartners.active';
        let params = {
            partners: 1,
            active: true
        };
        if (name !== null) {
            params.name = name;
            cacheName += '.' + name;
        }
        let data = JSON.parse(localStorage.getItem(cacheName));
        if (ignoreCache || !data) {
            $_tablets.get(params).then((data) => {
                localStorage.setItem(cacheName, JSON.stringify(data));
                $scope.tabletPartners = data.items;
                $scope.$apply();
                if (typeof fb === 'function') {
                    fb(data.items);
                }
            }).catch((err) => {
                //
            });
        } else {
            $scope.tabletPartners = data.items;
            if (typeof fb === 'function') {
                fb(data.items);
            }
        }//
    };
    
    // get active terminal parteners only list (without cache)
    function getActiveTabletPartners(name = null) {
        let params = {
            active: 1,
            partners: 1,
            namesIdsOnly: 1,
            prepaidAccount: 1
        };
        if (typeof name === 'string') {
            params.name = name;
        }
        return $_tablets.get(params, null, 1000).then((partners) => {
            return Promise.resolve(partners.items);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }    

    $scope.showAddEditTablet = (tablet = null) => {
        $mdDialog.show({
            templateUrl: currentDir + 'add.edit.tablet.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                tablet: tablet,
                getPartners: getActiveTabletPartners
            },
            controller: ($scope, toast, tablet, getPartners) => {
                $scope.isEdit = tablet !== null;
                $scope.tablet = tablet === null ? {} : tablet;
                $scope.selectedPartner = null;
                $scope.prepaidAccounts = [];
                if (tablet) {
                    let pa = tablet.PrepaidAccountId;
                    if (tablet.TabletPartner.Client.PrepaidAccount !== null && pa === null) {
                        pa = tablet.TabletPartner.Client.PrepaidAccount._id;
                    }
                    if (pa !== null) {
                        $scope.prepaidAccounts = [
                            {
                                id: null,
                                name: 'Aucun'
                            },
                            {
                                id: pa,
                                name: 'Compte prépayé N° ' + pa
                            }
                        ];
                    }
                }
                getPartners(null).then((data) => {
                    $scope.tabletPartners = data;
                    if (tablet) {
                        $scope.selectedPartner = $scope.tabletPartners.find(partner => partner._id === tablet.TabletPartner._id);
                    }
                });
                $scope.onPartnerSelect = function () {
                    $scope.tablet.TabletPartnerId = $scope.selectedPartner._id;
                    $scope.prepaidAccounts = [];
                    if ($scope.selectedPartner.Client.PrepaidAccount !== null) {
                        let pa = $scope.selectedPartner.Client.PrepaidAccount;
                        $scope.prepaidAccounts = [
                            {
                                id: null,
                                name: 'Aucun'
                            },
                            {
                                id: pa._id,
                                name: 'Compte prépayé N° ' + pa._id
                            }
                        ];
                    }
                };
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        let promise = $scope.isEdit ? $_tablets.update($scope.tablet, $scope.tabletId) : $_tablets.add($scope.tablet);
                        promise.then(() => {
                            $mdDialog.hide();
                            $scope.getTablets();
                            toast.show('La tablette a bien été ' + ($scope.isEdit ? 'mise à jour' : 'ajoutée.'), 'success', true, 2500);
                        }).catch((err) => {
                            toast.show(err, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        }).then((answer) => {
            //
        }, () => {
            //
        });
    };

//------------------------------------------------------------------------------

    switch (path.substr(0, 14)) {
        case '/tablets':
            $scope.getTablets();
            break;
        case '/tablets/partn':
            getPartnersTablets();
            break;
        default:
            getTablet($scope.tabletId);
    }

    $scope.getPartners();

});
